"use strict";
/**
 * # sw ------------- sp&tablet add
 # sw ------------- chk Modern PC (not IE) SMP 強化版 IE11 add

 */
let appVersion,getDevice,ios,uaName,uaNameVer,userAgent;userAgent=window.navigator.userAgent.toLowerCase();appVersion=window.navigator.appVersion.toLowerCase();uaName="unknown";ios=void 0;uaNameVer=void 0;if(userAgent.indexOf("msie")!==-1){uaName="ie";if(appVersion.indexOf("msie 6.")!==-1){uaName="ie6"}else if(appVersion.indexOf("msie 7.")!==-1){uaName="ie7"}else if(appVersion.indexOf("msie 8.")!==-1){uaName="ie8"}else if(appVersion.indexOf("msie 9.")!==-1){uaName="ie9"}else if(appVersion.indexOf("msie 10.")!==-1){uaName="ie10"}}else if(userAgent.indexOf("trident/7")!==-1){uaName="ie11"}else if(userAgent.indexOf("chrome")!==-1){uaName="chrome"}else if(userAgent.indexOf("ipad")!==-1){ios=navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);uaNameVer=[parseInt(ios[1],10),parseInt(ios[2],10),parseInt(ios[3]||0,10)];uaName="ipad"}else if(userAgent.indexOf("ipod")!==-1){ios=navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);uaNameVer=[parseInt(ios[1],10),parseInt(ios[2],10),parseInt(ios[3]||0,10)];uaName="ipod"}else if(userAgent.indexOf("iphone")!==-1){ios=navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);uaNameVer=[parseInt(ios[1],10),parseInt(ios[2],10),parseInt(ios[3]||0,10)];uaName="iphone"}else if(userAgent.indexOf("android")!==-1){uaName="android"}else if(userAgent.indexOf("safari")!==-1){uaName="safari"}else if(userAgent.indexOf("gecko")!==-1){uaName="gecko"}else if(userAgent.indexOf("opera")!==-1){uaName="opera"}else{if(userAgent.indexOf("mobile")!==-1){uaName="mobile"}}getDevice=function(){let uaSpTab=navigator.userAgent;if(uaSpTab.indexOf("iPhone")>0||uaSpTab.indexOf("iPod")>0||uaSpTab.indexOf("Android")>0&&uaSpTab.indexOf("Mobile")>0){return"sp"}else if(uaSpTab.indexOf("iPad")>0||uaSpTab.indexOf("Android")>0){return"tablet"}}();


let __SWNS = {};
(($, win, doc)=> {    // JQ ES6 sw Pack 0622 babel

  let $win = $(win),
      $doc = $(doc),
      swns = {
        theDate() {
          let today = new Date();
          let day = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
          let y = today.getFullYear();
          let m = today.getMonth() + 1;
          let d = today.getDate();
          let w = today.getDay();
          let H = today.getHours();
          let M = today.getMinutes();
          let S = today.getSeconds();
          if (m < 10) { m = "0" + m; }
          if (d < 10) { d = "0" + d; }
          if (H < 10) { H = "0" + H; }
          if (M < 10) { M = "0" + M; }
          if (S < 10) { S = "0" + S; }
          return `${day[w]}:` + m + "/" + d + "/" + H + ":" + M + "." + S;
        },
        getUrlVars() {
          // fixup ハッシュある場合 0622
          let vars = [];
          let max = 0;
          let hash = '';
          let array = '';
          let url = window.location.search;
          let hasHash = window.location.hash;
          let hasHashSplit = hasHash.slice(0).split('?');
          let fixUrl = hasHash ? '#' + hasHashSplit[1] : url;
          hash = fixUrl.slice(1).split('&');
          max = hash.length;
          let i = 0;
          while (i < max) {
            array = hash[i].split('=');
            /*fixup 日本語にデコードUTF8
            *  ※ すべての文字がエンコードされるので、/tatsuya/js/index.htm などのパスを表す文字列には注意が必要です。
              ※ スラッシュ (/) もエンコードされるので、Web サーバーへの要求として送信する場合は無効になります。
              ※ 文字列に URI コンポーネントが複数含まれる場合は、encodeURI 関数を使用します。
              */
            //vars.push(array[0]);
            vars.push( decodeURIComponent(array[0]) );
            //vars[array[0]] = array[1];
            vars[array[0]] = decodeURIComponent(array[1]);
            i++;
          }
          return vars;
        }
      }; // swns{}

  /* animateCallback
  * # http://takustaqu.hatenablog.com/entry/2014/03/02/025558
    # http://qiita.com/nekoneko-wanwan/items/e8114c6e34d2950a7b28
    # animationstart（delay完了後）
  */
  $.fn.animateStartCallback = function(callback) {
    let alias;
    alias = 'animationstart webkitAnimationStart';
    return this.each(function() {
      $(this).on(alias, function() {
        return callback.call(this);
      });
    });
  };
  $.fn.animationIterationCallback = function(callback) {
    let alias;
    alias = 'animationiteration webkitAnimationIteration';
    return this.each(function() {
      $(this).on(alias, function() {
        $(this).off(alias);  // sw on/off(unbind is .one())
        $(this).off(alias);
        return callback.call(this);
      });
    });
  };
  $.fn.animateEndCallback = function(callback) {
    let alias;
    alias = 'animationend webkitAnimationEnd';
    return this.each(function() {
      $(this).on(alias, function() {
        $(this).off(alias);
        return callback.call(this);
      });
    });
  };
  /* transitionCallback */
  $.fn.transitionStartCallback = function(callback) {
    let alias;
    alias = 'transitionstart webkitTransitionStart';
    return this.each(function() {
      $(this).bind(alias, function() {
        $(this).unbind(alias);
        return callback.call(this);
      });
    });
  };
  $.fn.transitionEndCallback = function(callback) {
    let alias;
    alias = 'webkitTransitionEnd transitionend';
    return this.each(function() {
      $(this).bind(alias, function() {
        $(this).unbind(alias);
        return callback.call(this);
      });
    });
  };

  /* Mobile support */
  //if ((doc.ontouchstart !== void 0) || (doc.createTouch !== void 0)) {  $("body").addClass("has_ontouch"); }
  /* Mobile support */
  $("body").addClass("" + uaName);
  if (getDevice === 'sp') {
    $("body").addClass("sp");
  } else if (getDevice === 'tablet') {
    $("body").addClass("tablet");
  }

  /* plugin Type BlockLink */
  $.fn.blocklinkAssy = function () { // bind under this
    $(this).on("click", function (e) {
      e.preventDefault();
      win.open($(this).find("a").attr("href"), $(this).find("a").attr("target") || "_self");
    }).css({cursor: "pointer"});
  };


  // do it DRF
  $(()=> {

    $(".hNavi").blocklinkAssy();


    // ?catnum=6&placenum_post_tag=tag_2&placenum_kaiinn_seitag=&low=ひくい&high=たかい&kodawari%5B%5D=2%E9%9A%8E%E4%BB%A5%E4%B8%8A%E4%BD%8F%E6%88%B8&submit=%E6%A4%9C%E7%B4%A2
    let getUrlVarsVal = [];
    getUrlVarsVal = swns.getUrlVars();
    //console.info("[_sw.js Ready] getQuery", getUrlVarsVal);  // #------- sw Log --(´･_･`)


    /**
     * swAnchor
     * @ fixup 外部リンクを避ける修正 & if(target.length) {}
     */
    $('a.swAnchor').click(function () {
      let speed = 800;
      let href = $(this).attr("href");
      if (href.indexOf("#") === 0) {
        let target = $(href === "#" || href === "" ? 'html' : href);
        if (target.length) {
          let position = target.offset().top;
          $("html, body").animate({scrollTop: position - 55}, speed, "swing");
          return false;
        }
      }
    });

    $win.on("load", function() {
      /**
      * add tab trigger
      * https://dev-hirokomanner-group.withltd.com/?opentab=Prof-e
      * https://hirokomanner-group.withltd.com/?opentab=About-e&id=about-nishide
      */
     if (getUrlVarsVal['opentab']) {
       let $trigTab = $("ul#trigTab li");
       let $trigCont = $("#trigCont div.tab-pane");
       $trigTab.removeClass("active");
       $trigCont.removeClass("active");
       $(`ul#trigTab li#${getUrlVarsVal['opentab']}`).addClass("active");
       $(`#trigCont div.tab-pane.${getUrlVarsVal['opentab']}`).addClass("active");
       
       if (getUrlVarsVal['id']) {
         let speed = 800;
         let target = "#" + getUrlVarsVal['id'];
         //console.info(target);  // #------- sw Log --(´･_･`)
   
         if (target.length) {
           let position = $(target).offset().top;
           $("html, body").animate({scrollTop: position - 55}, speed, "swing");
           return false;
         }
        }
     }
    });

  }); // DRF }
})(jQuery, window, document);
